<template>
  <div>
    <div class="container has-background-white">
      <div class="columns p-t-md p-b-sm">
        <div class="column is-9">
          <img class="logoImg" :src="`/src/assets/images/${subdomain}_logo.png`">
        </div>
        <div class="column">
          <div class="dropdown is-right is-hoverable is-pulled-right">
            <div class="dropdown-trigger">
              <span aria-haspopup="true" aria-controls="dropdown-menu">
                <!-- <img src="/src/assets/images/avatar.jpg" width="30px" height="30px"> -->
                <span class="pos-rel dark-grey" style="da">
                  Hi,
                  <b>{{ `${firstName}` }}</b>
                </span>
                <span v-if="firstName" class="vbadge-login">
                  {{ firstName.charAt(0).toUpperCase() }}
                </span>
              </span>
            </div>
            <div id="dropdown-menu" class="dropdown-menu dropdown-menu-grey r-8 profile-menu" role="menu">
              <div class="dropdown-content">
                <router-link to="/myprofile" class="dropdown-item">
                  <SvgIcon icon="profile" :has-hover-state="false" fill="#aaafb7" />
                  <span class="dropdown-item-label">
                    My Profile
                  </span>
                </router-link>
                <router-link to="/myfeedbacks" class="dropdown-item">
                  <SvgIcon icon="comment" :has-hover-state="false" fill="#aaafb7" />
                  <span class="dropdown-item-label">
                    My Feedbacks
                  </span>
                </router-link>
                <router-link to="/logout" class="dropdown-item" @click.native="logout()">
                  <SvgIcon icon="logout" :has-hover-state="false" fill="#aaafb7" />
                  <span class="dropdown-item-label">
                    Logout
                  </span>
                </router-link>
                <hr class="dropdown-divider">
                <router-link :to="`/releasenotes/${$root.Version}`" class="dropdown-item">
                  <SvgIcon icon="version" :has-hover-state="false" fill="#aaafb7" />
                  <span class="dropdown-item-label">
                    Version #{{ $root.Version }}
                  </span>
                </router-link>
                <router-link to="/releasenotes" class="dropdown-item">
                  <SvgIcon icon="versions" :has-hover-state="false" fill="#aaafb7" />
                  <span class="dropdown-item-label">
                    Release Notes
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="container">
          <div class="navbar-brand">
            <a role="button"
               class="navbar-burger burger"
               aria-label="menu"
               aria-expanded="false"
               data-target="navbarBasicExample"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-start">
              <!-- <router-link :to="item.routeTo" :class="{'router-link-active' : item.isActiveRoute($route)}">{{ item.label }}</router-link> -->
              <router-link to="/account" class="navbar-item">
                Accounts
              </router-link>
              <router-link to="/contact" class="navbar-item">
                Contacts
              </router-link>
              <router-link to="/opportunity" class="navbar-item">
                Opportunities
              </router-link>
              <!-- <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                  Contacts
                </a>
                <div class="navbar-dropdown">
                  <router-link to="/contact/AddAccounts" class="navbar-item">
                    Add Accounts
                  </router-link>
                </div>
              </div> -->
              <a class="navbar-item" :class="{'active' : isInvoiceTab}">
                Invoice Validation
              </a>
              <!-- <a class="navbar-item">
                Market Information
              </a> -->
              <div v-if="canRunReport" class="navbar-item has-dropdown is-hoverable" :class="{'active' : isReportsTab}">
                <a class="navbar-link">
                  Reports
                </a>
                <div class="navbar-dropdown">
                  <div class="nested dropdown navbar-item">
                    NHH - Electricity
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/preapprovalreport/elec" class="navbar-item">
                          Pre-Approval Report
                        </router-link>
                        <!-- Approval Report -->
                        <router-link v-if="isEIC" to="/report/eic/approvalreport/elec" class="navbar-item">
                          Approval Report
                        </router-link>
                        <router-link v-else-if="isMonarch" to="/report/monarch/approvalreport/elec" class="navbar-item">
                          Approval Report
                        </router-link>
                        <router-link v-else to="/report/elec/approvalreport" class="navbar-item">
                          Approval Report
                        </router-link>
                        <!-- Approval Report -->
                        <router-link to="/report/elec/consumptionreport" class="navbar-item">
                          Consumption Report
                        </router-link>
                        <router-link to="/report/monthlyconsumptionreport/elec" class="navbar-item">
                          Monthly-Consumption Report
                        </router-link>
                        <router-link to="/report/elec/nhhtariffsummaryreport" class="navbar-item">
                          Tariff Summary Report By Site
                        </router-link>
                        <!-- Smith Bellerby Only Reports -->
                        <hr v-if="isSBB" class="navbar-divider">
                        <router-link v-if="isSBB" to="/report/elec/summaryrptfrmapprpt" class="navbar-item">
                          Shelter Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/nbcrptfrmapprpt/elec" class="navbar-item">
                          NBC Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/elec/eppingapprovalreport" class="navbar-item">
                          Epping Approval Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/elec/eppingrptfrmapprpt" class="navbar-item">
                          Epping Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/elec/threadneedleapprpt" class="navbar-item">
                          Thread Needle Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/elec/nonthreadneedleapprpt" class="navbar-item">
                          Non-Thread Needle Report
                        </router-link>
                        <!-- Smith Bellerby Only Reports -->
                      </div>
                    </div>
                  </div>
                  <template>
                    <hr class="navbar-divider">
                    <div class="nested dropdown navbar-item">
                      HH - Electricity
                      <div id="dropdown-menu" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <router-link to="/report/preapprovalreport/hhelec" class="navbar-item">
                            Pre-Approval Report
                          </router-link>
                          <!-- Approval Report -->
                          <router-link v-if="isEIC" to="/report/eic/approvalreport/hhelec" class="navbar-item">
                            Approval Report
                          </router-link>
                          <router-link v-else-if="isMonarch"
                                       to="/report/monarch/approvalreport/hhelec"
                                       class="navbar-item"
                          >
                            Approval Report
                          </router-link>
                          <router-link v-else to="/report/elec/hhapprovalreport" class="navbar-item">
                            Approval Report
                          </router-link>
                          <!-- Approval Report -->
                          <router-link to="/report/monthlyconsumptionreport/hhelec" class="navbar-item">
                            Monthly-Consumption Report
                          </router-link>
                          <router-link to="/report/elec/hhtariffsummaryreport" class="navbar-item">
                            Tariff Summary Report By Site
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </template>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Gas
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/preapprovalreport/gas" class="navbar-item">
                          Pre-Approval Report
                        </router-link>
                        <!-- Approval Report -->
                        <router-link v-if="isEIC" to="/report/eic/approvalreport/gas" class="navbar-item">
                          Approval Report
                        </router-link>
                        <router-link v-else-if="isMonarch" to="/report/monarch/approvalreport/gas" class="navbar-item">
                          Approval Report
                        </router-link>
                        <router-link v-else to="/report/gas/approvalreport" class="navbar-item">
                          Approval Report
                        </router-link>
                        <!-- Approval Report -->
                        <router-link to="/report/monthlyconsumptionreport/gas" class="navbar-item">
                          Monthly-Consumption Report
                        </router-link>
                        <router-link to="/report/elec/gastariffsummaryreport" class="navbar-item">
                          Tariff Summary Report By Site
                        </router-link>
                        <!-- Smith Bellerby Only Reports -->
                        <hr v-if="isSBB" class="navbar-divider">
                        <router-link v-if="isSBB" to="/report/nbcrptfrmapprpt/gas" class="navbar-item">
                          NBC Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/gas/eppingapprovalreport" class="navbar-item">
                          Epping Approval Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/gas/eppingrptfrmapprpt" class="navbar-item">
                          Epping Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/gas/sbb/threadneedlegasapprpt" class="navbar-item">
                          Thread Needle Report
                        </router-link>
                        <router-link v-if="isSBB" to="/report/gas/sbb/nonthreadneedlegasapprpt" class="navbar-item">
                          Non-Thread Needle Report
                        </router-link>
                        <!-- Smith Bellerby Only Reports -->
                      </div>
                    </div>
                  </div>
                  <template>
                    <hr class="navbar-divider">
                    <div class="nested dropdown navbar-item">
                      Water
                      <div id="dropdown-menu" class="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <router-link to="/report/preapprovalreport/water" class="navbar-item">
                            Pre-Approval Report
                          </router-link>
                          <router-link v-if="isMonarch"
                                       to="/report/monarch/approvalreport/water"
                                       class="navbar-item"
                          >
                            Approval Report
                          </router-link>
                          <router-link v-else-if="isESS"
                                       to="/report/ess/approvalreport/water"
                                       class="navbar-item"
                          >
                            Approval Report
                          </router-link>
                          <!-- Approval Report -->
                          <router-link v-else to="/report/eic/approvalreport/water" class="navbar-item">
                            Approval Report
                          </router-link>                          
                          <!-- Approval Report -->
                          <router-link to="/report/monthlyconsumptionreport/water" class="navbar-item">
                            Monthly-Consumption Report
                          </router-link>
                          <router-link to="/report/elec/watertariffsummaryreport" class="navbar-item">
                            Tariff Summary Report By Site
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </template>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Other Reports
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/sr183" class="navbar-item">
                          SR183 - Missing Half Hourly Data
                        </router-link>
                        <router-link to="/report/sr160A" class="navbar-item">
                          SR160A - Consumption Report
                        </router-link>
                        <router-link to="/report/sr160A_Gas" class="navbar-item">
                          SR160A - Gas Consumption Report
                        </router-link>
                        <router-link to="/report/sr168" class="navbar-item">
                          SR168 - Power Factor Monthly
                        </router-link>
                        <router-link to="/report/sr164" class="navbar-item">
                          SR164 - Triad Details
                        </router-link>
                        <router-link to="/report/sr181" class="navbar-item">
                          SR181 - Meter Reading Report
                        </router-link>
                        <router-link to="/report/sr529" class="navbar-item">
                          SR529 - Gas Invoice Details
                        </router-link>
                        <router-link to="/report/sr130" class="navbar-item">
                          SR130 - Elec Invoice Details
                        </router-link>
                        <router-link to="/report/sr529w" class="navbar-item">
                          SR529 - Water Invoice Details
                        </router-link>
                        <router-link to="/report/sr104e/elec" class="navbar-item">
                          SR104 - Invoice v Tariff Electric Report
                        </router-link>
                        <router-link to="/report/sr104g/gas" class="navbar-item">
                          SR104 - Invoice v Tariff Gas Report
                        </router-link>
                        <router-link to="/report/sr104w/water" class="navbar-item">
                          SR104 - Invoice v Tariff Water Report
                        </router-link>
                        <hr class="navbar-divider">
                        <div class="nested dropdown navbar-item">
                          Consumption
                          <div id="dropdown-menu" class="dropdown-menu" role="menu">
                            <div class="dropdown-content">
                              <router-link to="/report/sg184" class="navbar-item">
                                SG184 - Maximum Demand Report
                              </router-link>
                              <router-link to="/report/gr202" class="navbar-item">
                                GR202 - Daily Analysis Report
                              </router-link>
                              <router-link to="/report/SG180" class="navbar-item">
                                SG180 - Half Hourly Data Report
                              </router-link>
                              <router-link to="/report/SG181" class="navbar-item">
                                SG181 - Daily Totals Report
                              </router-link>
                              <router-link to="/report/SG182" class="navbar-item">
                                SG182 - Weekly Totals Report
                              </router-link>
                              <router-link to="/report/SG185" class="navbar-item">
                                SG185 - Load Factor Report
                              </router-link>
                            </div>
                          </div>
                        </div>
                        <hr class="navbar-divider">
                        <router-link to="/report/tr301" class="navbar-item">
                          TR301 - Renewal Status Report
                        </router-link>
                        <router-link to="/report/tr335" class="navbar-item">
                          TR335 - Red Amber Green Consumption Report
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/sd368" class="navbar-item">
                          SD368 - Tender Elec Report
                        </router-link>
                        <router-link to="/report/sd369" class="navbar-item">
                          SD369 - Tender Gas Report
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/hhdata" class="navbar-item">
                          HHD - Export HH Data Report
                        </router-link>
                        <router-link to="/report/exporttariff" class="navbar-item">
                          0055 - Export Tariff Report
                        </router-link>
                        <router-link to="/report/billhistory" class="navbar-item">
                          Bill History Report
                        </router-link>
                        <router-link to="/report/capacitymarket" class="navbar-item">
                          Capacity Market Report
                        </router-link>
                        <router-link to="/report/WaterTender" class="navbar-item">
                          Water Tender Report
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr v-if="isAdmin" class="navbar-divider">
                  <router-link to="/report/billcountreport" class="navbar-item">
                    Bills Processed By User
                  </router-link>
                  <div class="nested dropdown navbar-item">
                    Admin
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div v-if="canUnReport" class="dropdown-content">
                        <router-link to="/report/unreport" class="navbar-item">
                          Un-Report
                        </router-link>
                      </div>
                      <div v-if="canAccessCommission" class="dropdown-content">
                        <router-link to="/report/Commission" class="navbar-item">
                          Commission Report
                        </router-link>
                      </div>
                      <div v-if="canAccessCommission" class="dropdown-content">
                        <router-link to="/report/NewCommission" class="navbar-item">
                          New Commission
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Misc Reports
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/AccountClass" class="navbar-item">
                          Account Class
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/CCL" class="navbar-item">
                          CCL Report
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/Registrations" class="navbar-item">
                          Registrations
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/NoticeLetter" class="navbar-item">
                          Notice Letter
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/Contract" class="navbar-item">
                          Contract
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/RecentContracts" class="navbar-item">
                          Recent Contracts
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/SiteList" class="navbar-item">
                          Site List
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/BusinessClosed" class="navbar-item">
                          Business Closed
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/AccountOwner" class="navbar-item">
                          Account Owner
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/AllContracts" class="navbar-item">
                          All Contracts
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/SupplierAnalysis" class="navbar-item">
                          Supplier Analysis Report
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- Special Reports -->
                  <hr class="navbar-divider">
                  <router-link to="/report/SpecialReport" class="navbar-item">
                    Special Reports
                  </router-link>
                  <!--Metering contract details-->
                  <div v-if="isEIC" class="nested dropdown navbar-item">
                    Metering
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/contReport" class="navbar-item">
                          Contract Reports
                        </router-link>
                      </div>
                      <hr class="navbar-divider">
                      <div class="dropdown-content">
                        <router-link to="/report/DataCollection" class="navbar-item">
                          Data Collection Report
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!--End of Metering contract details-->
                </div>
              </div>
              <div v-if="canRunAutomation" class="navbar-item has-dropdown is-hoverable" :class="{'active' : isBATab}">
                <a class="navbar-link">
                  Bill Automation
                </a>
                <div class="navbar-dropdown">
                  <div class="nested dropdown navbar-item">
                    NHH Electricity
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/billautomation/sseelectric" class="navbar-item">
                          SSE Electricity
                        </router-link>
                        <router-link to="/billautomation/bgelectric" class="navbar-item">
                          British Gas Electricity
                        </router-link>
                        <router-link to="/billautomation/edfelectric" class="navbar-item">
                          EDF Electricity
                        </router-link>
                        <router-link to="/billautomation/npowerelectric" class="navbar-item">
                          NPower Electricity
                        </router-link>
                        <router-link to="/billautomation/npowerjuniferelectric" class="navbar-item">
                          NPower Electricity (Junifer Format)
                        </router-link>
                        <router-link to="/billautomation/smartestreading" class="navbar-item">
                          Smartest Meter Read Upload
                        </router-link>
                        <router-link to="/billautomation/smartestelectric" class="navbar-item">
                          Smartest Electricity
                        </router-link>
                        <router-link to="/billautomation/havenelectric" class="navbar-item">
                          Haven Electricity
                        </router-link>
                        <router-link to="/billautomation/totalenergiesElectric" class="navbar-item">
                          Total Energies Electricity
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    HH Electricity
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/billautomation/smartesthhelectric" class="navbar-item">
                          Smartest HH Electricity
                        </router-link>
                        <router-link to="/billautomation/edfhhelectric" class="navbar-item">
                          EDF HH Electricity
                        </router-link>
                        <router-link to="/billautomation/havenpowerhhelectric" class="navbar-item">
                          Haven Power HH Electricity
                        </router-link>
                        <router-link to="/billautomation/shellukhhelectric" class="navbar-item">
                          Shell UK HH Electricity
                        </router-link>
                        <router-link to="/billautomation/totalenergieshhelectric" class="navbar-item">
                          Total Energies HH Electricity
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Gas
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/billautomation/GazpromGas" class="navbar-item">
                          Gazprom Gas
                        </router-link>
                      </div>
                      <div class="dropdown-content">
                        <router-link v-if="isMonarch" to="/billautomation/SEFEGas" class="navbar-item">
                          SEFE Gas (Aster)
                        </router-link>
                      </div>
                      <div class="dropdown-content">
                        <router-link to="/billautomation/CoronaGas" class="navbar-item">
                          Corona Gas
                        </router-link>
                      </div>
                      <div class="dropdown-content">
                        <router-link to="/billautomation/RegentGas" class="navbar-item">
                          Regent Gas
                        </router-link>
                      </div>
                      <div class="dropdown-content">
                        <router-link to="/billautomation/TotalGas" class="navbar-item">
                          Total Gas
                        </router-link>
                      </div>
                      <div class="dropdown-content">
                        <router-link to="/billautomation/TotalGasAdditionalChrg" class="navbar-item">
                          Total Gas(Meter Charges Format)
                        </router-link>
                      </div>
                      <!-- <div class="dropdown-content">
                        <router-link to="/billautomation/opusGas" class="navbar-item">
                          Opus Gas
                        </router-link>
                      </div>-->
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Water
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/billautomation/HavenDraxWater" class="navbar-item">
                          Drax Water
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <!-- <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    HH Electricity
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/billautomation/hhsmartest" class="navbar-item">
                          Smartest HH Electricity
                        </router-link>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="navbar-item has-dropdown is-hoverable" :class="{'active' : isMoreTab}">
                <a class="navbar-link">
                  More
                </a>
                <div class="navbar-dropdown">
                  <router-link to="/more/dno" class="navbar-item">
                    DNO
                  </router-link>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Transmission Charges
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/triad" class="navbar-item">
                          TRIAD
                        </router-link>
                        <router-link to="/more/tuostariff" class="navbar-item">
                          TUoS Tariffs
                        </router-link>
                        <router-link to="/more/tuosloss" class="navbar-item">
                          TUoS Loss
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="nested dropdown navbar-item">
                    Supplier
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/supplier" class="navbar-item">
                          Supplier
                        </router-link>
                        <router-link to="/more/fit" class="navbar-item">
                          Supplier FIT - Quarterly
                        </router-link>
                        <router-link to="/more/fityearly" class="navbar-item">
                          Supplier FIT - Yearly
                        </router-link>
                        <router-link to="/more/emr" class="navbar-item">
                          Supplier EMR
                        </router-link>
                        <router-link to="/more/meteremr" class="navbar-item">
                          Meter EMR
                        </router-link>
                        <router-link to="/more/rocharge" class="navbar-item">
                          RO Charges
                        </router-link>
                        <router-link to="/more/cmcharge" class="navbar-item">
                          Capacity Market Charges
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="nested dropdown navbar-item">
                    Flex Charges
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/elecflex" class="navbar-item">
                          Electricity Flex Charges
                        </router-link>
                        <router-link to="/more/gasflex" class="navbar-item">
                          Gas Flex Charges
                        </router-link>
                        <router-link to="/more/gasbaseunit" class="navbar-item">
                          Gas Base Unit Charges
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <router-link to="/more/duos" class="navbar-item">
                    DUoS Charges
                  </router-link>
                  <router-link to="/more/duosloss" class="navbar-item">
                    DUoS Loss
                  </router-link>
                  <hr class="navbar-divider">
                  <router-link to="/more/bsuos" class="navbar-item">
                    BSUoS Price
                  </router-link>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Other Charges
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/ccl" class="navbar-item">
                          CCL
                        </router-link>
                        <router-link to="/more/aahed" class="navbar-item">
                          AAHDEC
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <router-link to="/more/hhdata" class="navbar-item">
                    Half-Hourly Data
                  </router-link>
                  <router-link to="/more/hhdatachannel" class="navbar-item">
                    Half-Hourly Data Channel
                  </router-link>
                  <router-link to="/more/hhuploadtemplatebased" class="navbar-item">
                    Half-Hourly Data Upload
                  </router-link>
                  <div class="nested dropdown navbar-item">
                    Gas Amr Charges
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/gasamrchargesupload" class="navbar-item">
                          Gas AMR Charges Upload
                        </router-link>
                        <router-link to="/more/gasamrchargesdownload" class="navbar-item">
                          Gas AMR Charges Download
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-if="isSuperAdmin" class="nested dropdown navbar-item">
                    Uploads
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/tuoslossupload" class="navbar-item">
                          TUoS Loss Upload
                        </router-link>
                        <router-link to="/more/bsuosupload" class="navbar-item">
                          BSUoS Upload
                        </router-link>
                        <router-link to="/more/gasbaseunitrateupload" class="navbar-item">
                          Gas Base UnitRates Upload
                        </router-link>
                        <router-link to="/more/duosbandupload" class="navbar-item">
                          DUoS TimeBand Upload
                        </router-link>
                        <router-link to="/more/duoslvhvload" class="navbar-item">
                          DUoS LV, HV and UMS charges Upload
                        </router-link>
                        <router-link to="/more/duosllfload" class="navbar-item">
                          DUoS LLF charges Upload
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <router-link to="/more/ReportAccountGroup" class="navbar-item">
                    Report - Account Group
                  </router-link>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Site Automation
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/SiteDownload" class="navbar-item">
                          Sites Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/TempUpload" class="navbar-item">
                            Temp Bulk Upload
                          </router-link>
                        </div>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/SiteUpload" class="navbar-item">
                            Sites Bulk Upload
                          </router-link>
                        </div>
                        <router-link to="/more/SiteReportDownload" class="navbar-item">
                          Sites Report
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Half-Hourly Automation
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/HHElecDetailTemplateDownload" class="navbar-item">
                          HH - ElecDetails Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/HHElecDetailTemplateUpload" class="navbar-item">
                            HH - ElecDetails Bulk Upload
                          </router-link>
                        </div>
                        <router-link to="#" class="navbar-item">
                          HH - ElecDetails Report
                        </router-link>
                        <router-link to="/more/HHDownload" class="navbar-item">
                          HH - Tariff Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/HHTariffupload" class="navbar-item">
                            HH - Tariff Upload
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Non Half-Hourly Automation
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/ElecDetailTemplateDownload" class="navbar-item">
                          NHH - ElecDetails Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/NHHElecDetailTemplateUpload" class="navbar-item">
                            NHH - ElecDetails Bulk Upload
                          </router-link>
                        </div>
                        <router-link to="#" class="navbar-item">
                          NHH - ElecDetails Report
                        </router-link>
                        <router-link to="/more/NHHDownload" class="navbar-item">
                          NHH - Tariff Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/NhhTariffupload" class="navbar-item">
                            NHH - Tariff Upload
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="navbar-divider">
                  <div class="nested dropdown navbar-item">
                    Gas Automation
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/more/GasDetailTemplateDownload" class="navbar-item">
                          GasDetails Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/GasElecDetailTemplateUpload" class="navbar-item">
                            GasDetails Bulk Upload
                          </router-link>
                        </div>
                        <router-link to="#" class="navbar-item">
                          GasDetails Report
                        </router-link>
                        <router-link to="/more/GasDownload" class="navbar-item">
                          Gas - Tariff Template
                        </router-link>
                        <div v-if="canUnReport" class="dropdown-content">
                          <router-link to="/more/GasTariffUpload" class="navbar-item">
                            Gas - Tariff Upload
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link">
                  Extra Reports
                </a>
                <div class="navbar-dropdown">
                  <div class="nested dropdown navbar-item">
                    Approval Report (Reported)
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/Mon_ApprovalReport" class="navbar-item">
                          Approval Report NHH (Reported)
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/Mon_ApprovalReport_consumption" class="navbar-item">
                          Approval Report NHH with Consumption
                        </router-link>
                        <hr class="navbar-divider">
                      </div>
                    </div>
                  </div>
                  <div class="nested dropdown navbar-item">
                    Utility Report
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/utildatareport_Elec" class="navbar-item">
                          Electric Utility Report-NHH
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/utildatareport_Gas" class="navbar-item">
                          Gas Utility Report
                        </router-link>
                        <hr class="navbar-divider">
                      </div>
                    </div>
                  </div>
                  <!-- remove this div -->

                  <div class="nested dropdown navbar-item">
                    Accural Report
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/accuralreport_Elec" class="navbar-item">
                          Electric Accural Report-NHH
                        </router-link>
                        <hr class="navbar-divider">
                        <router-link to="/report/accuralreport_Gas" class="navbar-item">
                          Gas Accural Report
                        </router-link>
                        <hr class="navbar-divider">
                      </div>
                    </div>
                  </div>
                  <!-- new report consumption report -->
                  <div class="nested dropdown navbar-item">
                    Consumption Report
                    <div id="dropdown-menu" class="dropdown-menu" role="menu">
                      <div class="dropdown-content">
                        <router-link to="/report/ConsumptionReport_CNB" class="navbar-item">
                          Consumption Report for CNB
                        </router-link>
                        <hr class="navbar-divider">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="navbar-item pos-abs r-0 t-0" @click="isShowModal = true">
                <a class="button is-light is-small" style="border-radius:20px;top:5px !important;right:0px !important;" @click="showModal()">
                  <i class="fa fa-search" />
                  &nbsp;&nbsp;Advanced Search
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="advsearch">
          <VModal :is-open="isShowModal"
                  :close-button="true"
                  :show-footer="false"
                  title="Advanced Search"
                  :error="alertMessage"
                  @closeModal="closeModal()"
          >
            <template v-slot:body>
              <div class="container advanceModal">
                <div class="columns">
                  <div class="column">
                    <div class="field is-horizontal">
                      <!--                       <div v-if="isMonarchDomain" class="select">
                        <select v-model="modal.searchType">
                          <option value="2">
                            Site Name
                          </option>
                          <option value="3">
                            Postcode
                          </option>
                          <option value="4">
                            MPAN (Last 13 character)
                          </option>
                          <option value="5">
                            MPRN
                          </option>
                          <option value="6">
                            Invoice No
                          </option>
                          <option value="7">
                            Net
                          </option>
                          <option value="8">
                            Total
                          </option>
                          <option value="9">
                            Account No
                          </option>
                          <option value="10">
                            Address
                          </option>
                          <option value="11">
                            Site Reference
                          </option>
                          <option value="12">
                            Account Reference
                          </option>
                          <option value="13">
                            Account Alias
                          </option>
                          <option value="14">
                            Water SPID
                          </option>
                          <option value="15">
                            Sewerage SPID
                          </option>
                          <option value="17">
                            CNB
                          </option>
                        </select>
                      </div> -->
                      <div class="select">
                        <select v-model="modal.searchType">
                          <option value="1">
                            Location Id
                          </option>
                          <option value="16">
                            Site Id
                          </option>
                          <option value="2">
                            Site Name
                          </option>
                          <option value="3">
                            Postcode
                          </option>
                          <option value="4">
                            MPAN (Last 13 character)
                          </option>
                          <option value="5">
                            MPRN
                          </option>
                          <option value="6">
                            Invoice No
                          </option>
                          <option value="7">
                            Net
                          </option>
                          <option value="8">
                            Total
                          </option>
                          <option value="9">
                            Account No
                          </option>
                          <option value="10">
                            Address
                          </option>
                          <option value="11">
                            Site Reference
                          </option>
                          <option value="12">
                            Account Reference
                          </option>
                          <option value="13">
                            Account Alias
                          </option>
                          <option value="14">
                            Water SPID
                          </option>
                          <option value="15">
                            Sewerage SPID
                          </option>
                          <option value="17">
                            CNB
                          </option>
                          <option value="18">
                            MSN
                          </option>
                        </select>
                      </div>
                      <div style="height:2.2em;padding: 6px;margin-left: 10px;">
                        Equal to
                      </div>
                      <div class="m-l-sm">
                        <input ref="searchText"
                               v-model="modal.searchText"
                               class="input searchText"
                               @keyup.enter="search()"
                        >
                      </div>
                      <div class="m-l-sm">
                        <button class="button is-primary"
                                :disabled="!enableSearch || !modal.searchType"
                                @click="search()"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <VTable v-if="modal.Messages && modal.Messages.length"
                        :header="modalheader"
                        :content="modal.Messages"
                        :is-pagination="false"
                >
                  <template v-slot:content="{ data }">
                    <div class="columns">
                      <template v-if="data.col.key == 'Status'">
                        <SvgIcon v-if="data.row.Status"
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="tick"
                                 size="sm"
                                 fill="#48c78e"
                                 :has-hover-state="false"
                        />
                        <SvgIcon v-else
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="cancel"
                                 size="sm"
                                 fill="#f14668"
                                 :has-hover-state="false"
                        />
                      </template>
                      <template v-if="data.col.key == 'ElecMeterStatus'">
                        <SvgIcon v-if="data.row.ElecMeterStatus === 'Active'"
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="tick"
                                 size="sm"
                                 fill="#48c78e"
                                 :has-hover-state="false"
                        />
                        <SvgIcon v-else
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="cancel"
                                 size="sm"
                                 fill="#f14668"
                                 :has-hover-state="false"
                        />
                      </template>
                      <template v-if="data.col.key == 'GasMeterStatus'">
                        <SvgIcon v-if="data.row.GasMeterStatus"
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="tick"
                                 size="sm"
                                 fill="#48c78e"
                                 :has-hover-state="false"
                        />
                        <SvgIcon v-else
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="cancel"
                                 size="sm"
                                 fill="#f14668"
                                 :has-hover-state="false"
                        />
                      </template>
                      <template v-if="data.col.key == 'WaterMeterStatus'">
                        <SvgIcon v-if="data.row.WaterMeterStatus"
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="tick"
                                 size="sm"
                                 fill="#48c78e"
                                 :has-hover-state="false"
                        />
                        <SvgIcon v-else
                                 pos-rel-left="12px"
                                 pos-rel-top="10px"
                                 class="navbar-item-svg"
                                 icon="cancel"
                                 size="sm"
                                 fill="#f14668"
                                 :has-hover-state="false"
                        />
                      </template>
                    </div>
                  </template>
                </VTable>
                <div v-if="modal.Messages && modal.Messages.length === 0"
                     class="p-sm"
                     style="border:1px solid #e7ecf1;"
                >
                  No records found!
                </div>
              </div>
            </template>
          </VModal>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { http } from '../../services'
import { AccountResolver } from '../../services/resolvers'
import { actions } from '../../vuex/constants'
import { mapGetters } from 'vuex'

const accountResolver = new AccountResolver()

export default {
  data () {
    return {
      subdomain: '',
      loading: true,
      content: [],
      isShowModal: false,
      modalheader: this.getModalHeader(),
      modal: {
        searchType: '4',
        searchText: null,
        Messages: null
      }
    }
  },
  computed: {
    ...mapGetters([ 'userName', 'Role', 'firstName', 'Permission', 'screenAccess', 'alertMessage' ]),
    isInvoiceTab () {
      return this.$route.path.includes('site/')
    },
    isExtraTab () {
      return this.$route.path.includes('extrareport/')
    },
    isMoreTab () {
      return this.$route.path.includes('more/')
    },
    isSuperAdmin () {
      return this.Role && (this.Role === 'SuperAdmin' || this.Role === 'DevSuperAdmin')
    },
    isAdmin () {
      return this.Role && (this.Role === 'SuperAdmin' || this.Role === 'Admin')
    },
    isBATab () {
      return this.$route.path.includes('billautomation/')
    },
    isReportsTab () {
      return this.$route.path.includes('report/')
    },
    canRunReport () {
      return this.Permission && this.Permission.Report
    },
    canUnReport () {
      return this.Permission && this.Permission.UnReport
    },
    canAccessCommission () {
      return this.screenAccess.includes('9E3A6B34-3857-452F-BC71-341FFA43A7B3')
    },
    canRunAutomation () {
      return this.Permission && this.Permission.Automation
    },
    isESS () {
      return [ 'uat-essutility', 'ess-tbd','dev-ess','ess' ].includes(this.subdomain)
    },
    isEIC () {
      return [ 'uat-eic', 'eic', 'dev-eic' ,'dev9' ].includes(this.subdomain)
      // return this.Permission && this.Permission.Report
    },
    isSBB () {
      return [ 'uat-smithbellerby', 'smithbellerby', 'dev2','dev-ssb' ].includes(this.subdomain)
    },
    isMonarch () {
      return [ 'uat-monarch', 'monarchmatrix','dev-monarch' ].includes(this.subdomain)
    },
    enableSearch () {
      return this.modal.searchType && this.modal.searchText && this.modal.searchText.length > 0
    },
    isMonarchDomain () {
      return [ 'uat-monarch', 'monarchmatrix', 'dev-monarch' ].includes(this.subdomain)
    },
  },
  created () {
    this.subdomain = location.hostname.split('.').shift()
  },
  mounted () {
    //this.getContent()
    this.initialLoadType = '4'
    this.initialLoad = null
    this.currentRouteReload = false
    this.highlight('')
  },
  methods: {
    logout () {
      this.$store.dispatch(actions.auth.logout)
        .then(() => {
          this.$router.push({ path: '/login' })
        }, error => {
          this.$router.push({ path: '/login' })
        })
    },
    showItem (item) {
      this.$router.push({ path: `/account/${item.id}` })
    },

    highlight (itemToHighlight) {
      if (!this.searchFilter) {
        return itemToHighlight
      }
      return itemToHighlight.replace(new RegExp(this.searchFilter, 'ig'), match => {
        return '<strong">' + this.searchFilter + '</strong>' + (match.replace(this.searchFilter, ''))
      })
    },

    showModal () {
      this.isShowModal = true
      //this.reLoadType = 'showElec'
      //debugger
      if (this.modal.Messages && this.modal.Messages.length === 0) {
        this.modal.Messages = null
      }
      else {  //if (this.modal.searchText && this.modal.searchText.length > 0)
        this.modal = {
          searchType: '4',
          searchText: null,
          Messages: null
        }
      }
    },

    closeModal () {
      this.isShowModal = false
    },
    getModalHeader () {
      return [
        { key: 'AccountName', value: 'Customer', onClick: (item) => this.showCustomer(item) },
        //{ key: 'SiteId', value: 'Location Id', onClick: (item) => this.showSite(item) },
        { key: 'SiteId', value: 'Site Id', onClick: (item) => this.showSite(item) },
        { key: 'LocationId', value: 'Location Id', onClick: (item) => this.showSite(item) },
        { key: 'SiteName', value: 'Site Name' },
        { key: 'Address', value: 'Address' },
        { key: 'Status', value: 'Status', width: '10%', custom: true },
        { key: 'ElecMeterStatus', value: 'Elect Details Status', width: '10%', custom: true },
        { key: 'GasMeterStatus', value: 'Gas Details Status', width: '10%', custom: true },
        { key: 'WaterMeterStatus', value: 'Water Details Status', width: '10%', custom: true },
      ]
    },
    search () {
      this.loading = true
      
      
      return http.post(`globalsearch`, {
        LocationId: this.modal.searchType === '1' ? this.modal.searchText : null,
        SiteName: this.modal.searchType === '2' ? this.modal.searchText : null,
        Postcode: this.modal.searchType === '3' ? this.modal.searchText : null,
        Mpan: this.modal.searchType === '4' ? this.modal.searchText : null,
        Mprn: this.modal.searchType === '5' ? this.modal.searchText : null,
        InvoiceNo: this.modal.searchType === '6' ? this.modal.searchText : null,
        Net: this.modal.searchType === '7' ? this.modal.searchText : null,
        Total: this.modal.searchType === '8' ? this.modal.searchText : null,
        AccountNumber: this.modal.searchType === '9' ? this.modal.searchText : null,
        Address: this.modal.searchType === '10' ? this.modal.searchText : null,
        SiteRef: this.modal.searchType === '11' ? this.modal.searchText : null,
        AccountReference: this.modal.searchType === '12' ? this.modal.searchText : null,
        AccountAlias: this.modal.searchType === '13' ? this.modal.searchText : null,
        WaterSPId: this.modal.searchType === '14' ? this.modal.searchText : null,
        SewerageSPId: this.modal.searchType === '15' ? this.modal.searchText : null,
        SiteId: this.modal.searchType === '16' ? this.modal.searchText : null,
        CNB: this.modal.searchType === '17' ? this.modal.searchText : null,
        MSN: this.modal.searchType === '18' ? this.modal.searchText : null
      }).then(response => {
        
        this.loading = false
        this.modal.Messages = (response || [])
        console.log('global search response ', response)
        console.log('modal messages', this.modal.Messages[0])
        console.log('this.modal.searchType', this.modal.searchType)
        console.log('this.initialLoad', this.initialLoad)
        console.log('this.initialLoadType', this.initialLoadType)
        console.log('this.reLoadType', this.reLoadType)
        console.log('Route Name', this.$router.currentRoute.path) 
        this.currentRoutePath = this.$router.currentRoute.path
        debugger
        if (this.modal.Messages[0].ElecDetailId !== null && this.modal.Messages.length === 1) {
          this.showElec(this.modal.Messages[0])
          //this.reLoadType = ''
          if (this.$router.currentRoute.path.toLowerCase().includes('/elec') && this.initialLoad !== this.modal.Messages[0].ElecDetailId) {
            console.log('Route Name Electric', this.$router.currentRoute.path) 
            this.$router.go()
          }
          
          this.initialLoad = this.modal.Messages[0].ElecDetailId
          //this.reLoadType = 'showElec'
          this.isShowModal = false
          this.closeModal()
        }

        else if (this.modal.Messages[0].GasDetailId !== null && this.modal.Messages.length === 1) {
          this.showGas(this.modal.Messages[0])
          //this.reLoadType = 'showGas'
          if (this.$router.currentRoute.path.toLowerCase().includes('/gas') && this.initialLoad !== this.modal.Messages[0].GasDetailId) {
            this.$router.go()
            console.log('Route Name Gas', this.$router.currentRoute.path) 
          }
          this.initialLoad = this.modal.Messages[0].GasDetailId
          //this.isShowModal = false
          this.closeModal()
        }

        else if (this.modal.Messages[0].WaterDetailId !== null && this.modal.Messages.length === 1) {
          this.showWater(this.modal.Messages[0])
          //this.reLoadType = 'showWater'
          if (this.$router.currentRoute.path.toLowerCase().includes('/water')  && this.initialLoad !== this.modal.Messages[0].WaterDetailId) {
            this.$router.go()
            console.log('Route Name Water', this.$router.currentRoute.path) 
          }
          this.initialLoad = this.modal.Messages[0].WaterDetailId
          
          this.isShowModal = false
          this.closeModal()
        }

        else if (this.modal.Messages.length === 1) {
          console.log('this.modal.searchType Site', this.modal.searchType)
          console.log('this.initialLoad Site', this.initialLoadType)
          console.log('Route Name Site', this.$router.currentRoute.path) 
          this.showSiteFromSearch(this.modal.Messages[0])
          //this.reLoadType = 'showSite'
          if (this.$router.currentRoute.path.toLowerCase().includes('/site')) {
            //debugger
            this.$router.go()
          }
          this.initialLoad = this.modal.Messages[0].SiteId
          
          this.isShowModal = false
          this.closeModal()
        }
        this.initialLoad = false
        this.initialLoadType = this.modal.searchType
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },

    showCustomer (item) {
      this.$router.push({ path: `/account/${item.AccountId}`, reload: true })
      //this.$router.go(0)
      this.isShowModal = false
      this.closeModal()
    },

    showSite (item) {
      debugger
      console.log('Route Name Site', this.$router.currentRoute.path) 
      if (this.$router.currentRoute.path.toLowerCase().includes('/site')) {
        this.currentRouteReload = true
      }
      this.$router.push({ path: `/site/${item.SiteId}`, reload: true  })
      if (this.currentRouteReload) {
        //debugger
        this.$router.go(0)
      }
      this.isShowModal = false
      this.closeModal()
    },
    showSiteFromSearch (item) {
      //debugger
      this.$router.push({ path: `/site/${item.SiteId}` })
      //this.$router.go(0)
      this.isShowModal = false
      this.closeModal()
    },
    showElec (item) {
      debugger
      this.$router.push({ path: `/site/${item.SiteId}/elec/${item.ElecDetailId}` })
      
    },

    showGas (item) {
      debugger

      this.$router.push({ path: `/site/${item.SiteId}/gas/${item.GasDetailId}` })
      
    },
    showWater (item) {
      this.$router.push({ path: `/site/${item.SiteId}/water/${item.WaterDetailId}` })
      
    },


  }
}
</script>
<style lang="scss">
#app {
  .dropdown-trigger {
    padding: 6px 10px;
    background: #f3f6f9;
    border-radius: 0.42rem;
  }
  .advsearch {
    .modal-card {
      width: 1050px;
      .searchText, button {
        height: 2.2em;
      }
      select {
        width: 250px;
      }
    }
  }
  .dropdown.is-right .dropdown-menu {
    right: 9px;
  }
  .profile-menu {
    .dropdown-content {
      .dropdown-item {
        .dropdown-item-label {
          position: relative;
          top:-6px;
          left: 4px;
        }
        padding-bottom: 0;
        margin-bottom: 3px; 
        &:hover {
          span, svg, path, rect {
            color: #36c6d3;
            fill: #36c6d3;
          }
        }
      }
    }
  }
}
.navbar-dropdown {
  border-radius: 0 !important;
  .nested.dropdown {
    padding-right: 2.5rem !important;
    &:hover {
      .dropdown-menu {
        display: block;
      }
    } 
    .dropdown-menu {
      z-index: 20;
      top: -4px;
      margin-left: 100%;
      background: #444d58;
      .dropdown-content {
        background: #444d58;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    &:after {
      border: 3px solid transparent;
      border-radius: 2px;
      border-right: 0;
      border-top: 0;
      content: " ";
      display: block;
      height: 0.625em;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: rotate(225deg);
      transform-origin: center;
      width: 0.625em;
      border-color: #337ab7;
      margin-top: -0.325em;
      right: 1.125em;
    }
  }
}
</style>