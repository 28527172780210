var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container has-background-white" }, [
      _c("div", { staticClass: "columns p-t-md p-b-sm" }, [
        _c("div", { staticClass: "column is-9" }, [
          _c("img", {
            staticClass: "logoImg",
            attrs: { src: "/src/assets/images/" + _vm.subdomain + "_logo.png" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            { staticClass: "dropdown is-right is-hoverable is-pulled-right" },
            [
              _c("div", { staticClass: "dropdown-trigger" }, [
                _c(
                  "span",
                  {
                    attrs: {
                      "aria-haspopup": "true",
                      "aria-controls": "dropdown-menu"
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "pos-rel dark-grey", staticStyle: {} },
                      [
                        _vm._v("\n                Hi,\n                "),
                        _c("b", [_vm._v(_vm._s("" + _vm.firstName))])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.firstName
                      ? _c("span", { staticClass: "vbadge-login" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.firstName.charAt(0).toUpperCase()) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-grey r-8 profile-menu",
                  attrs: { id: "dropdown-menu", role: "menu" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "dropdown-content" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/myprofile" }
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              icon: "profile",
                              "has-hover-state": false,
                              fill: "#aaafb7"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dropdown-item-label" }, [
                            _vm._v(
                              "\n                  My Profile\n                "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/myfeedbacks" }
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              icon: "comment",
                              "has-hover-state": false,
                              fill: "#aaafb7"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dropdown-item-label" }, [
                            _vm._v(
                              "\n                  My Feedbacks\n                "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/logout" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.logout()
                            }
                          }
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              icon: "logout",
                              "has-hover-state": false,
                              fill: "#aaafb7"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dropdown-item-label" }, [
                            _vm._v(
                              "\n                  Logout\n                "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/releasenotes/" + _vm.$root.Version }
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              icon: "version",
                              "has-hover-state": false,
                              fill: "#aaafb7"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dropdown-item-label" }, [
                            _vm._v(
                              "\n                  Version #" +
                                _vm._s(_vm.$root.Version) +
                                "\n                "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/releasenotes" }
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              icon: "versions",
                              "has-hover-state": false,
                              fill: "#aaafb7"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "dropdown-item-label" }, [
                            _vm._v(
                              "\n                  Release Notes\n                "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "nav",
        {
          staticClass: "navbar",
          attrs: { role: "navigation", "aria-label": "main navigation" }
        },
        [
          _c("div", { staticClass: "container" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "navbar-menu",
                attrs: { id: "navbarBasicExample" }
              },
              [
                _c(
                  "div",
                  { staticClass: "navbar-start" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "navbar-item", attrs: { to: "/account" } },
                      [_vm._v("\n              Accounts\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      { staticClass: "navbar-item", attrs: { to: "/contact" } },
                      [_vm._v("\n              Contacts\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "navbar-item",
                        attrs: { to: "/opportunity" }
                      },
                      [_vm._v("\n              Opportunities\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "navbar-item",
                        class: { active: _vm.isInvoiceTab }
                      },
                      [
                        _vm._v(
                          "\n              Invoice Validation\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.canRunReport
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "navbar-item has-dropdown is-hoverable",
                            class: { active: _vm.isReportsTab }
                          },
                          [
                            _c("a", { staticClass: "navbar-link" }, [
                              _vm._v(
                                "\n                Reports\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "navbar-dropdown" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  NHH - Electricity\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/preapprovalreport/elec"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Pre-Approval Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.isEIC
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/eic/approvalreport/elec"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm.isMonarch
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/monarch/approvalreport/elec"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/approvalreport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/elec/consumptionreport"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/monthlyconsumptionreport/elec"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Monthly-Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/elec/nhhtariffsummaryreport"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Tariff Summary Report By Site\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c("hr", {
                                                  staticClass: "navbar-divider"
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/summaryrptfrmapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Shelter Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/nbcrptfrmapprpt/elec"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        NBC Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/eppingapprovalreport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Epping Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/eppingrptfrmapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Epping Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/threadneedleapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Thread Needle Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/elec/nonthreadneedleapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Non-Thread Needle Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                [
                                  _c("hr", { staticClass: "navbar-divider" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "nested dropdown navbar-item"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    HH - Electricity\n                    "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          attrs: {
                                            id: "dropdown-menu",
                                            role: "menu"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dropdown-content" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/preapprovalreport/hhelec"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Pre-Approval Report\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.isEIC
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/eic/approvalreport/hhelec"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _vm.isMonarch
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/monarch/approvalreport/hhelec"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/elec/hhapprovalreport"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/monthlyconsumptionreport/hhelec"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Monthly-Consumption Report\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/elec/hhtariffsummaryreport"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Tariff Summary Report By Site\n                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                _vm._v(" "),
                                _c("hr", { staticClass: "navbar-divider" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Gas\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/preapprovalreport/gas"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Pre-Approval Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.isEIC
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/eic/approvalreport/gas"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm.isMonarch
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/monarch/approvalreport/gas"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/gas/approvalreport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Approval Report\n                      "
                                                    )
                                                  ]
                                                ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/monthlyconsumptionreport/gas"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Monthly-Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/report/elec/gastariffsummaryreport"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Tariff Summary Report By Site\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c("hr", {
                                                  staticClass: "navbar-divider"
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/nbcrptfrmapprpt/gas"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        NBC Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/gas/eppingapprovalreport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Epping Approval Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/gas/eppingrptfrmapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Epping Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/gas/sbb/threadneedlegasapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Thread Needle Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.isSBB
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/gas/sbb/nonthreadneedlegasapprpt"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Non-Thread Needle Report\n                      "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                [
                                  _c("hr", { staticClass: "navbar-divider" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "nested dropdown navbar-item"
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Water\n                    "
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "dropdown-menu",
                                          attrs: {
                                            id: "dropdown-menu",
                                            role: "menu"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "dropdown-content" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/preapprovalreport/water"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Pre-Approval Report\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.isMonarch
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/monarch/approvalreport/water"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _vm.isESS
                                                ? _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/ess/approvalreport/water"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "navbar-item",
                                                      attrs: {
                                                        to:
                                                          "/report/eic/approvalreport/water"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          Approval Report\n                        "
                                                      )
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/monthlyconsumptionreport/water"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Monthly-Consumption Report\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/report/elec/watertariffsummaryreport"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          Tariff Summary Report By Site\n                        "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                _vm._v(" "),
                                _c("hr", { staticClass: "navbar-divider" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Other Reports\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr183" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR183 - Missing Half Hourly Data\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr160A" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR160A - Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/sr160A_Gas"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR160A - Gas Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr168" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR168 - Power Factor Monthly\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr164" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR164 - Triad Details\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr181" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR181 - Meter Reading Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr529" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR529 - Gas Invoice Details\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr130" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR130 - Elec Invoice Details\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sr529w" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR529 - Water Invoice Details\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/sr104e/elec"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR104 - Invoice v Tariff Electric Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/sr104g/gas"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR104 - Invoice v Tariff Gas Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/sr104w/water"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SR104 - Invoice v Tariff Water Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "nested dropdown navbar-item"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Consumption\n                        "
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "dropdown-menu",
                                                    attrs: {
                                                      id: "dropdown-menu",
                                                      role: "menu"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-content"
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/sg184"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              SG184 - Maximum Demand Report\n                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/gr202"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              GR202 - Daily Analysis Report\n                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/SG180"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              SG180 - Half Hourly Data Report\n                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/SG181"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              SG181 - Daily Totals Report\n                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/SG182"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              SG182 - Weekly Totals Report\n                            "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            staticClass:
                                                              "navbar-item",
                                                            attrs: {
                                                              to:
                                                                "/report/SG185"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              SG185 - Load Factor Report\n                            "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/tr301" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        TR301 - Renewal Status Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/tr335" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        TR335 - Red Amber Green Consumption Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sd368" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SD368 - Tender Elec Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/sd369" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        SD369 - Tender Gas Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/hhdata" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        HHD - Export HH Data Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/exporttariff"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        0055 - Export Tariff Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/billhistory"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Bill History Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/capacitymarket"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Capacity Market Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/WaterTender"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Water Tender Report\n                      "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isAdmin
                                  ? _c("hr", { staticClass: "navbar-divider" })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "navbar-item",
                                    attrs: { to: "/report/billcountreport" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Bills Processed By User\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Admin\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/report/unreport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Un-Report\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.canAccessCommission
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/report/Commission"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Commission Report\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.canAccessCommission
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/NewCommission"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        New Commission\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr", { staticClass: "navbar-divider" }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Misc Reports\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/AccountClass"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Account Class\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: { to: "/report/CCL" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        CCL Report\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/Registrations"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Registrations\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/NoticeLetter"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Notice Letter\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/Contract"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Contract\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/RecentContracts"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Recent Contracts\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/SiteList"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Site List\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/BusinessClosed"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Business Closed\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/AccountOwner"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Account Owner\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/AllContracts"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        All Contracts\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/report/SupplierAnalysis"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Supplier Analysis Report\n                      "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("hr", { staticClass: "navbar-divider" }),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "navbar-item",
                                    attrs: { to: "/report/SpecialReport" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Special Reports\n                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isEIC
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "nested dropdown navbar-item"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Metering\n                  "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "dropdown-menu",
                                            attrs: {
                                              id: "dropdown-menu",
                                              role: "menu"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/more/contReport"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Contract Reports\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "navbar-divider"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/report/DataCollection"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        Data Collection Report\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canRunAutomation
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "navbar-item has-dropdown is-hoverable",
                            class: { active: _vm.isBATab }
                          },
                          [
                            _c("a", { staticClass: "navbar-link" }, [
                              _vm._v(
                                "\n                Bill Automation\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "navbar-dropdown" }, [
                              _c(
                                "div",
                                { staticClass: "nested dropdown navbar-item" },
                                [
                                  _vm._v(
                                    "\n                  NHH Electricity\n                  "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: {
                                        id: "dropdown-menu",
                                        role: "menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/sseelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        SSE Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to: "/billautomation/bgelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        British Gas Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/edfelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        EDF Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/npowerelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        NPower Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/npowerjuniferelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        NPower Electricity (Junifer Format)\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/smartestreading"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Smartest Meter Read Upload\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/smartestelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Smartest Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/havenelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Haven Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/totalenergiesElectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Total Energies Electricity\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("hr", { staticClass: "navbar-divider" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "nested dropdown navbar-item" },
                                [
                                  _vm._v(
                                    "\n                  HH Electricity\n                  "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: {
                                        id: "dropdown-menu",
                                        role: "menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/smartesthhelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Smartest HH Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/edfhhelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        EDF HH Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/havenpowerhhelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Haven Power HH Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/shellukhhelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Shell UK HH Electricity\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/totalenergieshhelectric"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Total Energies HH Electricity\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("hr", { staticClass: "navbar-divider" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "nested dropdown navbar-item" },
                                [
                                  _vm._v(
                                    "\n                  Gas\n                  "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: {
                                        id: "dropdown-menu",
                                        role: "menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to: "/billautomation/GazpromGas"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Gazprom Gas\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _vm.isMonarch
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass: "navbar-item",
                                                  attrs: {
                                                    to:
                                                      "/billautomation/SEFEGas"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        SEFE Gas (Aster)\n                      "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to: "/billautomation/CoronaGas"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Corona Gas\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to: "/billautomation/RegentGas"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Regent Gas\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to: "/billautomation/TotalGas"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Total Gas\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/TotalGasAdditionalChrg"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Total Gas(Meter Charges Format)\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("hr", { staticClass: "navbar-divider" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "nested dropdown navbar-item" },
                                [
                                  _vm._v(
                                    "\n                  Water\n                  "
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-menu",
                                      attrs: {
                                        id: "dropdown-menu",
                                        role: "menu"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "dropdown-content" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "navbar-item",
                                              attrs: {
                                                to:
                                                  "/billautomation/HavenDraxWater"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                        Drax Water\n                      "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "navbar-item has-dropdown is-hoverable",
                        class: { active: _vm.isMoreTab }
                      },
                      [
                        _c("a", { staticClass: "navbar-link" }, [
                          _vm._v("\n                More\n              ")
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "navbar-dropdown" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/dno" }
                              },
                              [
                                _vm._v(
                                  "\n                  DNO\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Transmission Charges\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/triad" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        TRIAD\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/tuostariff" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        TUoS Tariffs\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/tuosloss" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        TUoS Loss\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Supplier\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/supplier" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Supplier\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/fit" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Supplier FIT - Quarterly\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/fityearly" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Supplier FIT - Yearly\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/emr" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Supplier EMR\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/meteremr" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Meter EMR\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/rocharge" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        RO Charges\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/cmcharge" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Capacity Market Charges\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Flex Charges\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/elecflex" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Electricity Flex Charges\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/gasflex" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Gas Flex Charges\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/gasbaseunit" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Gas Base Unit Charges\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/duos" }
                              },
                              [
                                _vm._v(
                                  "\n                  DUoS Charges\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/duosloss" }
                              },
                              [
                                _vm._v(
                                  "\n                  DUoS Loss\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/bsuos" }
                              },
                              [
                                _vm._v(
                                  "\n                  BSUoS Price\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Other Charges\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/ccl" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        CCL\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/aahed" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        AAHDEC\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/hhdata" }
                              },
                              [
                                _vm._v(
                                  "\n                  Half-Hourly Data\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/hhdatachannel" }
                              },
                              [
                                _vm._v(
                                  "\n                  Half-Hourly Data Channel\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/hhuploadtemplatebased" }
                              },
                              [
                                _vm._v(
                                  "\n                  Half-Hourly Data Upload\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Gas Amr Charges\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to: "/more/gasamrchargesupload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Gas AMR Charges Upload\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to: "/more/gasamrchargesdownload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Gas AMR Charges Download\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isSuperAdmin
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "nested dropdown navbar-item"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Uploads\n                  "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-menu",
                                        attrs: {
                                          id: "dropdown-menu",
                                          role: "menu"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-content" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/more/tuoslossupload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        TUoS Loss Upload\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/more/bsuosupload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        BSUoS Upload\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to:
                                                    "/more/gasbaseunitrateupload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Gas Base UnitRates Upload\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/more/duosbandupload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        DUoS TimeBand Upload\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/more/duoslvhvload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        DUoS LV, HV and UMS charges Upload\n                      "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "navbar-item",
                                                attrs: {
                                                  to: "/more/duosllfload"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        DUoS LLF charges Upload\n                      "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "navbar-item",
                                attrs: { to: "/more/ReportAccountGroup" }
                              },
                              [
                                _vm._v(
                                  "\n                  Report - Account Group\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Site Automation\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/SiteDownload" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Sites Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/more/TempUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          Temp Bulk Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/more/SiteUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          Sites Bulk Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to: "/more/SiteReportDownload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Sites Report\n                      "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Half-Hourly Automation\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to:
                                                "/more/HHElecDetailTemplateDownload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        HH - ElecDetails Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/more/HHElecDetailTemplateUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          HH - ElecDetails Bulk Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "#" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        HH - ElecDetails Report\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/HHDownload" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        HH - Tariff Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to: "/more/HHTariffupload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          HH - Tariff Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Non Half-Hourly Automation\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to:
                                                "/more/ElecDetailTemplateDownload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        NHH - ElecDetails Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/more/NHHElecDetailTemplateUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          NHH - ElecDetails Bulk Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "#" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        NHH - ElecDetails Report\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/NHHDownload" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        NHH - Tariff Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/more/NhhTariffupload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          NHH - Tariff Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr", { staticClass: "navbar-divider" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "nested dropdown navbar-item" },
                              [
                                _vm._v(
                                  "\n                  Gas Automation\n                  "
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-menu",
                                    attrs: { id: "dropdown-menu", role: "menu" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "dropdown-content" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: {
                                              to:
                                                "/more/GasDetailTemplateDownload"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        GasDetails Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/more/GasElecDetailTemplateUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          GasDetails Bulk Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "#" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        GasDetails Report\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "router-link",
                                          {
                                            staticClass: "navbar-item",
                                            attrs: { to: "/more/GasDownload" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Gas - Tariff Template\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.canUnReport
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-content"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "navbar-item",
                                                    attrs: {
                                                      to:
                                                        "/more/GasTariffUpload"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          Gas - Tariff Upload\n                        "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "navbar-item has-dropdown is-hoverable" },
                      [
                        _c("a", { staticClass: "navbar-link" }, [
                          _vm._v(
                            "\n                Extra Reports\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "navbar-dropdown" }, [
                          _c(
                            "div",
                            { staticClass: "nested dropdown navbar-item" },
                            [
                              _vm._v(
                                "\n                  Approval Report (Reported)\n                  "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { id: "dropdown-menu", role: "menu" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-content" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/Mon_ApprovalReport"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Approval Report NHH (Reported)\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to:
                                              "/report/Mon_ApprovalReport_consumption"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Approval Report NHH with Consumption\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "nested dropdown navbar-item" },
                            [
                              _vm._v(
                                "\n                  Utility Report\n                  "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { id: "dropdown-menu", role: "menu" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-content" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/utildatareport_Elec"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Electric Utility Report-NHH\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/utildatareport_Gas"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Gas Utility Report\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "nested dropdown navbar-item" },
                            [
                              _vm._v(
                                "\n                  Accural Report\n                  "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { id: "dropdown-menu", role: "menu" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-content" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/accuralreport_Elec"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Electric Accural Report-NHH\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/accuralreport_Gas"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Gas Accural Report\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "nested dropdown navbar-item" },
                            [
                              _vm._v(
                                "\n                  Consumption Report\n                  "
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu",
                                  attrs: { id: "dropdown-menu", role: "menu" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dropdown-content" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "navbar-item",
                                          attrs: {
                                            to: "/report/ConsumptionReport_CNB"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                        Consumption Report for CNB\n                      "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticClass: "navbar-divider"
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "navbar-item pos-abs r-0 t-0",
                        on: {
                          click: function($event) {
                            _vm.isShowModal = true
                          }
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "button is-light is-small",
                            staticStyle: {
                              "border-radius": "20px",
                              top: "5px !important",
                              right: "0px !important"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showModal()
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-search" }),
                            _vm._v(
                              "\n                  Advanced Search\n              "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "advsearch" },
            [
              _c("VModal", {
                attrs: {
                  "is-open": _vm.isShowModal,
                  "close-button": true,
                  "show-footer": false,
                  title: "Advanced Search",
                  error: _vm.alertMessage
                },
                on: {
                  closeModal: function($event) {
                    return _vm.closeModal()
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "body",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "container advanceModal" }, [
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "div",
                                { staticClass: "field is-horizontal" },
                                [
                                  _c("div", { staticClass: "select" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.modal.searchType,
                                            expression: "modal.searchType"
                                          }
                                        ],
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.modal,
                                              "searchType",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "1" } },
                                          [
                                            _vm._v(
                                              "\n                          Location Id\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "16" } },
                                          [
                                            _vm._v(
                                              "\n                          Site Id\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "2" } },
                                          [
                                            _vm._v(
                                              "\n                          Site Name\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "3" } },
                                          [
                                            _vm._v(
                                              "\n                          Postcode\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "4" } },
                                          [
                                            _vm._v(
                                              "\n                          MPAN (Last 13 character)\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "5" } },
                                          [
                                            _vm._v(
                                              "\n                          MPRN\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "6" } },
                                          [
                                            _vm._v(
                                              "\n                          Invoice No\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "7" } },
                                          [
                                            _vm._v(
                                              "\n                          Net\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "8" } },
                                          [
                                            _vm._v(
                                              "\n                          Total\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "9" } },
                                          [
                                            _vm._v(
                                              "\n                          Account No\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "10" } },
                                          [
                                            _vm._v(
                                              "\n                          Address\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "11" } },
                                          [
                                            _vm._v(
                                              "\n                          Site Reference\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "12" } },
                                          [
                                            _vm._v(
                                              "\n                          Account Reference\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "13" } },
                                          [
                                            _vm._v(
                                              "\n                          Account Alias\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "14" } },
                                          [
                                            _vm._v(
                                              "\n                          Water SPID\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "15" } },
                                          [
                                            _vm._v(
                                              "\n                          Sewerage SPID\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "17" } },
                                          [
                                            _vm._v(
                                              "\n                          CNB\n                        "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "18" } },
                                          [
                                            _vm._v(
                                              "\n                          MSN\n                        "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "2.2em",
                                        padding: "6px",
                                        "margin-left": "10px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Equal to\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "m-l-sm" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.modal.searchText,
                                          expression: "modal.searchText"
                                        }
                                      ],
                                      ref: "searchText",
                                      staticClass: "input searchText",
                                      domProps: { value: _vm.modal.searchText },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.search()
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.modal,
                                            "searchText",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "m-l-sm" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "button is-primary",
                                        attrs: {
                                          disabled:
                                            !_vm.enableSearch ||
                                            !_vm.modal.searchType
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.search()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                        Search\n                      "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "container" },
                          [
                            _vm.modal.Messages && _vm.modal.Messages.length
                              ? _c("VTable", {
                                  attrs: {
                                    header: _vm.modalheader,
                                    content: _vm.modal.Messages,
                                    "is-pagination": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "content",
                                        fn: function(ref) {
                                          var data = ref.data
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "columns" },
                                              [
                                                data.col.key == "Status"
                                                  ? [
                                                      data.row.Status
                                                        ? _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "tick",
                                                              size: "sm",
                                                              fill: "#48c78e",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                        : _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "cancel",
                                                              size: "sm",
                                                              fill: "#f14668",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                data.col.key ==
                                                "ElecMeterStatus"
                                                  ? [
                                                      data.row
                                                        .ElecMeterStatus ===
                                                      "Active"
                                                        ? _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "tick",
                                                              size: "sm",
                                                              fill: "#48c78e",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                        : _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "cancel",
                                                              size: "sm",
                                                              fill: "#f14668",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                data.col.key == "GasMeterStatus"
                                                  ? [
                                                      data.row.GasMeterStatus
                                                        ? _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "tick",
                                                              size: "sm",
                                                              fill: "#48c78e",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                        : _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "cancel",
                                                              size: "sm",
                                                              fill: "#f14668",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                    ]
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                data.col.key ==
                                                "WaterMeterStatus"
                                                  ? [
                                                      data.row.WaterMeterStatus
                                                        ? _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "tick",
                                                              size: "sm",
                                                              fill: "#48c78e",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                        : _c("SvgIcon", {
                                                            staticClass:
                                                              "navbar-item-svg",
                                                            attrs: {
                                                              "pos-rel-left":
                                                                "12px",
                                                              "pos-rel-top":
                                                                "10px",
                                                              icon: "cancel",
                                                              size: "sm",
                                                              fill: "#f14668",
                                                              "has-hover-state": false
                                                            }
                                                          })
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3544182990
                                  )
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.modal.Messages &&
                            _vm.modal.Messages.length === 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "p-sm",
                                    staticStyle: { border: "1px solid #e7ecf1" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                No records found!\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navbar-brand" }, [
      _c(
        "a",
        {
          staticClass: "navbar-burger burger",
          attrs: {
            role: "button",
            "aria-label": "menu",
            "aria-expanded": "false",
            "data-target": "navbarBasicExample"
          }
        },
        [
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _vm._v(" "),
          _c("span", { attrs: { "aria-hidden": "true" } }),
          _vm._v(" "),
          _c("span", { attrs: { "aria-hidden": "true" } })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }