const SSEElectric = () => import('@/components/billautomation/SSEElectric')
const BritishGasElectric = () => import('@/components/billautomation/BritishGasElectric')
const EDFElectric = () => import('@/components/billautomation/EDFElectric')
const SmartestReading = () => import('@/components/billautomation/SmartestReading')
const SmartestElectric = () => import('@/components/billautomation/SmartestElectric')
const NPowerElectric = () => import('@/components/billautomation/NPowerElectric')
const NPowerJuniferElectric = () => import('@/components/billautomation/NPowerJuniferElectric')
const HavenElectric = () => import('@/components/billautomation/HavenElectric')
const TotalEnergiesElectric = () => import('@/components/billautomation/TotalEnergiesElectric')

const SmartestHHElectric = () => import('@/components/billautomation/SmartestHHElectric')
const EDFHHElectric = () => import('@/components/billautomation/EDFHHElectric')
const HavenPowerHHElectric = () => import('@/components/billautomation/HavenPowerHHElectric')
const ShellUKHHElectric = () => import('@/components/billautomation/ShellUKHHElectric')
const TotalEnergiesHHElectric = () => import('@/components/billautomation/TotalEnergiesHHElectric')

const opusGas = () => import('@/components/billautomation/opusGas')
const gazpromGas = () => import('@/components/billautomation/gazpromGas')
const TotalGas = () => import('@/components/billautomation/totalGas')
const CoronaGas = () => import('@/components/billautomation/coronaGas')
const RegentGas = () => import('@/components/billautomation/regentGas')
const SEFEGas = () => import('@/components/billautomation/sefeGas')
const TotalGasAdditionalChrg = () => import('@/components/billautomation/TotalGasAdditionalChrg')

const HavenDraxWater = () => import('@/components/billautomation/HavenDraxWater')

const routes = [
  {
    path: '/billautomation/sseelectric',
    name: 'SSEElectric',
    component: SSEElectric
  },
  {
    path: '/billautomation/bgelectric',
    name: 'BritishGasElectric',
    component: BritishGasElectric
  },
  {
    path: '/billautomation/edfelectric',
    name: 'EDFElectric',
    component: EDFElectric
  },
  {
    path: '/billautomation/npowerelectric',
    name: 'NPowerElectric',
    component: NPowerElectric
  },
  {
    path: '/billautomation/npowerjuniferelectric',
    name: 'NPowerJuniferElectric',
    component: NPowerJuniferElectric
  },
  {
    path: '/billautomation/havenelectric',
    name: 'HavenElectric',
    component: HavenElectric
  }, 
  {
    path: '/billautomation/totalenergieselectric',
    name: 'TotalEnergiesElectric',
    component: TotalEnergiesElectric
  },
  {
    path: '/billautomation/HavenDraxWater',
    name: 'HavenDraxWater',
    component: HavenDraxWater
  },
  {
    path: '/billautomation/SmartestReading',
    name: 'SmartestReading',
    component: SmartestReading
  },
  {
    path: '/billautomation/SmartestElectric',
    name: 'SmartestElectric',
    component: SmartestElectric
  },
  {
    path: '/billautomation/opusgas',
    name: 'opusGas',
    component: opusGas
  },
  {
    path: '/billautomation/gazpromGas',
    name: 'gazpromGas',
    component: gazpromGas
  },
  {
    path: '/billautomation/TotalGas',
    name: 'TotalGas',
    component: TotalGas
  },
  {
    path: '/billautomation/CoronaGas',
    name: 'CoronaGas',
    component: CoronaGas
  },
  {
    path: '/billautomation/RegentGas',
    name: 'RegentGas',
    component: RegentGas
  },
  {
    path: '/billautomation/SEFEGas',
    name: 'SEFEGas',
    component: SEFEGas
  },
  {
    path: '/billautomation/TotalGasAdditionalChrg',
    name: 'TotalGasAdditionalChrg',
    component: TotalGasAdditionalChrg
  },
  {
    path: '/billautomation/smartesthhelectric',
    name: 'SmartestHHElectric',
    component: SmartestHHElectric
  },
  {
    path: '/billautomation/edfhhelectric',
    name: 'EDFHHElectric',
    component: EDFHHElectric
  }
  ,
  {
    path: '/billautomation/havenpowerhhelectric',
    name: 'HavenPowerHHElectric',
    component: HavenPowerHHElectric
  },
  {
    path: '/billautomation/shellukhhelectric',
    name: 'ShellUKHHElectric',
    component: ShellUKHHElectric
  },
  {
    path: '/billautomation/totalenergieshhelectric',
    name: 'TotalEnergiesHHElectric',
    component: TotalEnergiesHHElectric
  }
]

export default routes